<template>
  <!-- BANNER TYPE 1 -->
  <div
    id="banner-type1"
    class="mb-2"
    :class="data.block"
  >
    <swiper
      ref="slider"
      class="swiper"
      :options="swiperOption"
    >
      <swiper-slide
        v-for="banner in banners"
        :key="banner._id"
      >
        <v-img
          contain
          :src="baseImgUrl + banner.mobile_image || image"
          @click="clickBanner(banner.mobile_hyperlink, $router)"
        />
      </swiper-slide>
      <div
        slot="pagination"
        class="swiper-pagination"
        :style="customPaginationColor"
      />
    </swiper>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { clickBannerEv } from './clickBanner'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters(['baseImgUrl', 'theme']),

    ...mapState({
      bannerDelay: state => state.websiteData.settings.banner_interval,
    }),

    banners() {
      return this.data.data.banner
    },

    swiperOption() {
      return {
        autoplay: {
          delay: +this.bannerDelay * 1000 || 3000,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      }
    },

    customPaginationColor() {
      const themePrimaryColor = this.$vuetify.theme.themes[this.theme]?.primary
      // 目前依 primary 顯色
      return {
        '--swiper-theme-color': themePrimaryColor || 'white',
      }
    },
  },

  methods: {
    clickBanner(hyperlink, router) {
      clickBannerEv(hyperlink, router)
    },
  },
}
</script>

<style lang="scss" scoped>

.swiper {
	.swiper-pagination {
		left: 16px;
		text-align: left;
	}
}
</style>
